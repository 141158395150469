import Layout from "layouts/Index";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { postRequestSessionApi } from "api/requestSessionApi";
import { getCurrentTimeZone } from "helpers/common_helper";
import { getLanguageApi } from "api/sessionApi";
import { getCategoriesApi } from "api/categoryApi";
import FormInput from "components/ui/Common/FormInput";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import TimezoneSelect from "elements/TimezoneSelect";
import createShortDynamicLink from "services/DynamicLink/shortLink";
import ShareModal from "components/Utilities/ShareModal";

const InviteSessionPage = () => {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [interestList, setInterestList] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [selectedPreferredDate, setSelectedPreferredDate] = useState(null);
  const [selectedOptionalDate, setSelectedOptionalDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [dynamicLink, setDynamicLink] = useState("");

  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });

  const defaultTimezone = () => {
    const timezoneId = 233;

    setSelectedTimezone({
      value: timezoneId,
      label: `System Default ${getCurrentTimeZone()}`,
    });
    setFieldValue("timezone_id", timezoneId);
  };

  const getLanguageList = (data) => {
    getLanguageApi()
      .then((res) => {
        setLanguageList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInterestList = (data) => {
    getCategoriesApi()
      .then((res) => {
        setInterestList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      toUserId: "",
      title: "",
      preferedDate: "",
      start_time: "",
      end_time: "",
      description: "",
      categoryId: "",
      languageId: "",
      timezone_id: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Session Title is required"),
      languageId: Yup.string().required("Language is required"),
      categoryId: Yup.string().required("Category is required"),
      preferedDate: Yup.date()
        .min(new Date(), "Date must be greater than today")
        .required("Date is required"),
      start_time: Yup.string()
        .test(
          "start_time",
          "Start time must be less than end time",
          function (value) {
            return value < this.parent.end_time;
          }
        )
        .required("Start Time is required"),
      end_time: Yup.string().test(
        "end_time",
        "End time must be greater than start time",
        function (value) {
          return value > this.parent.start_time;
        }
      ),
      description: Yup.string().required("Session details is required"),
      timezone_id: Yup.string().required("Timezone is required"),
    }),
    onSubmit: (values) => {
      let data = new FormData();
      data.append("toUserId", values.toUserId);
      data.append("title", values.title);
      data.append("preferedDate", values.preferedDate);
      data.append("start_time", values.start_time);
      data.append("end_time", values.end_time);
      data.append("description", values.description);
      data.append("categoryId", values.categoryId);
      data.append("languageId", values.languageId);
      data.append("timezone_id", values.timezone_id);

      postRequestSessionApi(data)
        .then((res) => {
          console.log(res?.data);
          if (res?.data?.success) {
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            createShortDynamicLink(
              "invite-session-request",
              res?.data?.data?.id
            )
              .then((res) => {
                setDynamicLink(res?.shortLink);
              })
              .catch((err) => {
                console.log(err);
              });

            window.$(document).ready(function () {
              window.$("#shareModal").modal("show");
            });

            formik.resetForm();
            setSelectedPreferredDate(null);
            setSelectedOptionalDate(null);
            setSelectedStartTime(null);
            setSelectedEndTime(null);
            setSelectedLanguage([]);
            setSelectedInterest([]);
            // setTimeout(() => {
            //   navigate("/dashboard");
            // }, 2000);
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleClearAll = () => {
    formik.resetForm();
  };

  useEffect(() => {
    getLanguageList(activeData);
    getInterestList(activeData);
    defaultTimezone();
  }, []);

  const handleSelectSearch = (selectedOption) => {
    const selectedValue = selectedOption?.value;
    setSelectedTimezone(selectedOption);
    setFieldValue("timezone_id", selectedValue);
  };

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Layout>
      <section className={`${isMobileView ? "main mt-3" : "main mt-5 py-5"}`}>
        <div className="container px-8">
          <div className="row">
            <div className="inner-block">
              <Link onClick={goBack}>
                <div className="icon-back mb-3">
                  {" "}
                  <i className="fa fa-arrow-left"></i>
                </div>
              </Link>

              <div
                className="card mob-card newmodal"
                style={{ marginBottom: isMobileView ? "8rem" : "3rem" }}
              >
                <div className="card-body create-session-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      {/* Session title */}
                      <div className="col-md-6">
                        <FormInput
                          name="title"
                          type="text"
                          label="Session Title"
                          placeholder="Enter Session Title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          error={errors.title}
                          touched={touched.title}
                        />
                      </div>
                      {/* Session title */}

                      {/* Language */}
                      <div className="col-md-6">
                        <div className="form-outline mb-4">
                          <label className="form-label mb-0" htmlFor="">
                            Language for the session (Choose from list)
                          </label>

                          <Select
                            name="languageId"
                            options={languageList?.map((item) => {
                              return {
                                value: item.id,
                                label: item.name,
                              };
                            })}
                            className=""
                            onChange={(selectedOption) => {
                              setFieldValue(
                                "languageId",
                                selectedOption?.value
                              );

                              setSelectedLanguage(selectedOption);
                            }}
                            onBlur={handleBlur}
                            value={selectedLanguage || []}
                          />
                          {errors.languageId && touched.languageId && (
                            <div className="text-danger">
                              {errors.languageId}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Language */}

                      {/* Category */}
                      <div className="col-md-6">
                        <div className="form-outline mb-4">
                          <label className="form-label mb-0" htmlFor="">
                            Category for the session (Choose from list)
                          </label>

                          <Select
                            name="categoryId"
                            options={interestList?.map((item) => {
                              return {
                                value: item.id,
                                label: item.name,
                              };
                            })}
                            className=""
                            onChange={(selectedOption) => {
                              setFieldValue(
                                "categoryId",
                                selectedOption?.value
                              );

                              setSelectedInterest(selectedOption);
                            }}
                            onBlur={handleBlur}
                            value={selectedInterest || []}
                          />
                          {errors.categoryId && touched.categoryId && (
                            <div className="text-danger">
                              {errors.categoryId}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Category */}

                      {/* Preferred Date */}
                      <div className="col-md-6">
                        <div className="form-outline mb-4">
                          <label className="form-label mb-0" htmlFor="">
                            Preferred Date
                          </label>
                          <div className="ui calendar datepicker">
                            <div className="d-flex align-items-center right icon">
                              <DatePicker
                                name="preferedDate"
                                className="form-control"
                                selected={selectedPreferredDate || ""}
                                onChange={(date) => {
                                  const formattedDate =
                                    moment(date).format("YYYY-MM-DD");
                                  setFieldValue("preferedDate", formattedDate);
                                  setSelectedPreferredDate(date);
                                }}
                                placeholderText="Select Date"
                                dateFormat="MMMM d, yyyy"
                              />
                              <i className="fa fa-calendar"></i>
                              {errors.preferedDate && touched.preferedDate && (
                                <div
                                  className="text-danger"
                                  style={{
                                    position: "absolute",
                                    marginTop: "5rem",
                                  }}
                                >
                                  {errors.preferedDate}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Preferred Date */}

                      {/* Start time */}
                      <div className="col-md-4">
                        <div className="form-outline mb-4">
                          <label className="form-label mb-0" htmlFor="">
                            Start Time
                          </label>
                          <div className="ui calendar timepicker">
                            <div className="d-flex align-items-center time right icon">
                              <DatePicker
                                name="start_time"
                                className="form-control"
                                selected={selectedStartTime || ""}
                                onChange={(date) => {
                                  const formattedTime =
                                    moment(date).format("HH:mm:ss");
                                  setFieldValue("start_time", formattedTime);
                                  setSelectedStartTime(date);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                dateFormat="h:mm aa"
                                placeholderText="Select Time"
                              />
                              <i className="fa fa-clock-o"></i>
                              {errors.start_time && touched.start_time && (
                                <div
                                  className="text-danger"
                                  style={{
                                    position: "absolute",
                                    marginTop: "5rem",
                                  }}
                                >
                                  {errors.start_time}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Start time */}

                      {/* End time */}
                      <div className="col-md-4">
                        <div className="form-outline mb-4">
                          <label className="form-label mb-0" htmlFor="">
                            End Time
                          </label>
                          <div className="ui calendar timepicker">
                            <div className="d-flex align-items-center time right icon">
                              <DatePicker
                                name="end_time"
                                className="form-control"
                                selected={selectedEndTime || ""}
                                onChange={(date) => {
                                  const formattedTime =
                                    moment(date).format("HH:mm:ss");
                                  setFieldValue("end_time", formattedTime);
                                  setSelectedEndTime(date);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                dateFormat="h:mm aa"
                                placeholderText="Select Time"
                              />
                              <i className="fa fa-clock-o"></i>
                              {errors.end_time && touched.end_time && (
                                <div
                                  className="text-danger"
                                  style={{
                                    position: "absolute",
                                    marginTop: "5rem",
                                  }}
                                >
                                  {errors.end_time}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End time */}

                      {/* Select time zone */}
                      <div className="col-md-4">
                        <div className="form-outline mb-4">
                          <label className="form-label mb-0" htmlFor="">
                            Select Time Zone
                          </label>

                          <TimezoneSelect
                            timezoneList={timezoneList}
                            handleSelectTimezone={handleSelectSearch}
                            selectedTimezone={selectedTimezone || []}
                          />
                          {errors.timezone_id && touched.timezone_id && (
                            <div className="text-danger">
                              {errors.timezone_id}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-outline mb-4">
                          <label className="form-label mb-0 mt-2" htmlFor="">
                            Session Details
                          </label>
                          <textarea
                            className="form-control"
                            name="description"
                            value={values.description || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="off"
                          ></textarea>
                          {errors.description && touched.description && (
                            <div className="text-danger">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Select time zone */}

                      {/* Submit Button */}
                      <div className="col-md-12">
                        <div className="text-center">
                          <button
                            onClick={handleSubmit}
                            type="submit"
                            className="btn btn-login btn-lg btn-block w-100 btn-submit"
                          >
                            Send Invite
                          </button>
                        </div>
                      </div>
                      {/* Submit Button */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        <ShareModal shareableLink={dynamicLink} />
      </section>
    </Layout>
  );
};

export default InviteSessionPage;
