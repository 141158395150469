import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo_universal-manch.svg";
import SearchBar from "components/ui/Common/SearchBar";
import CreateSessionModal from "pages/Dashboard/Modal/CreateSessionModal";
import { googleLogout } from "@react-oauth/google";
import { isUserLoggedIn } from "helpers/common_helper";
import { useSelector } from "react-redux";
import AvatarImage from "../assets/images/profile-placeholder.png";
import { handleUserImageError } from "helpers/common_helper";
import { isBrand } from "helpers/common_helper";
import { useMediaQuery } from "react-responsive";
import NotificationIcon from "../assets/images/Notification.png";
import { unreadNotificationCountApi } from "api/notificationApi";
import VerifyEmailModal from "pages/Dashboard/Modal/VerifyEmailModal";

const Header = () => {
  const loggedIn = isUserLoggedIn();
  const [notificationCount, setNotificationCount] = useState(0);

  const profile = useSelector((state) => state.profile.profile);

  const isEmailVerified = profile?.is_email_verified === 1 ? true : false;

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const isBrandUser = isBrand();

  const handleSearch = (searchValue) => {
    console.log("Search Value:", searchValue);
  };

  const getNotificationCount = () => {
    unreadNotificationCountApi()
      .then((res) => {
        setNotificationCount(res?.data?.unreadNotificationCount);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("_lluser");
    localStorage.removeItem("_lltoken");
    localStorage.removeItem("_lltimezone");
    localStorage.removeItem("_lltimezoneabbr");
    localStorage.removeItem("_isOnboarded");
    googleLogout();
    window.location.href = "/";
  };

  const isActiveTab = (path) => {
    return window.location.pathname === path;
  };



  return (
    <>
      {isMobileView ? (
        <>
          {/* Brand Header */}
          <nav className="navbar navbar-expand-md bg-light">
            <div className="container">
              <a className="navbar-brand" href="#">
                <img src={Logo} alt="logo" className="logo" width={"142"} />
              </a>

              <div className="d-flex align-items-center">
                <div className="dropdown">
                  <button
                    className="btn"
                    type="button"
                    style={{ padding: "unset" }}
                    onClick={() => {
                      window.location.href = "/notifications";
                    }}
                  >
                    <img
                      src={NotificationIcon}
                      height=""
                      width=""
                      alt="notification"
                    />
                    {notificationCount > 0 && (
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {notificationCount}
                        <span class="visually-hidden">unread messages</span>
                      </span>
                    )}
                  </button>
                </div>

                <div class="flex-shrink-0 dropdown ms-4">
                  <Link
                    to="#"
                    className="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        profile?.profileImageUrl === null ||
                        profile?.profileImageUrl === undefined
                          ? AvatarImage
                          : profile?.profileImageUrl
                      }
                      alt="mdo"
                      width="32"
                      height="32"
                      className="rounded-circle img-property-fit"
                    />
                  </Link>
                  <ul className="dropdown-menu text-small shadow">
                    <li>
                      <Link
                        className="dropdown-item profile-dropdown-item"
                        to={`/user-profile/${profile?.user_name}`}
                      >
                        <div className="row">
                          <div className="col-md-2 m-1 d-flex align-items-center justify-content-center">
                            <span>
                              <img
                                src={
                                  profile?.profileImageUrl === null ||
                                  profile?.profileImageUrl === undefined
                                    ? AvatarImage
                                    : profile?.profileImageUrl
                                }
                                width="42"
                                height="42"
                                alt="userimage"
                                className="rounded-circle img-property-fit"
                                onError={handleUserImageError}
                              />
                            </span>
                          </div>
                          <div className="col-md-9">
                            <strong>
                              {isBrandUser
                                ? profile?.brand_name
                                : profile?.first_name +
                                  " " +
                                  profile?.last_name}
                            </strong>
                            <p className="mb-0">{profile?.email}</p>
                            <p>{profile?.user_name}</p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    {/* <li>
                      <Link className="dropdown-item border-bottom profile-dropdown-item py-2">
                        Settings
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        className="dropdown-item border-bottom profile-dropdown-item py-2"
                        to={"/change-password"}
                      >
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item border-bottom profile-dropdown-item py-2"
                        to={"/my-bookmarks"}
                      >
                        My Bookmarks
                      </Link>
                    </li>
                    {/* <li>
                      <div className="d-flex">
                        <Link className="dropdown-item border-bottom profile-dropdown-item py-2 d-flex justify-content-between">
                          Language
                          <span>
                            <i
                              className="fa fa-globe"
                              style={{ fontSize: "24px" }}
                            ></i>
                          </span>
                        </Link>
                      </div>
                    </li> */}
                    <li>
                      <a
                        className="dropdown-item py-2 profile-dropdown-item"
                        href="#"
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Search bar and right side filter icon */}
            </div>
            {window.location.pathname === "/dashboard" && (
              <div className="d-flex">
                <SearchBar placeholder="Search" onSubmit={handleSearch} />
              </div>
            )}
          </nav>

          {/* Brand Header */}
          <nav className="navbar fixed-bottom navbar-light bg-light">
            <ul class="navbar-nav">
              <li class="nav-item">
                <Link
                  class={`nav-link ${
                    isActiveTab("/dashboard") ? "bottom-tab-active" : ""
                  }`}
                  to={"/dashboard"}
                >
                  <i class="fa fa-home tab-icons d-block mb-2"></i>
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`nav-link ${
                    isActiveTab("/my-join-list") ? "bottom-tab-active" : ""
                  }`}
                  to={"/my-join-list"}
                >
                  <i class="fa fa-list tab-icons d-block mb-2"></i>
                  Join List
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class="nav-link"
                  data-bs-target="#createSession"
                  data-bs-toggle="modal"
                >
                  <i class="fa fa-plus-circle tab-icons d-block mb-2"></i>
                  New
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`nav-link ${
                    isActiveTab("/user-requests") ? "bottom-tab-active" : ""
                  }`}
                  to={`/user-requests`}
                >
                  <i class="fa fa-users tab-icons d-block mb-2"></i>
                  Request
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  class={`nav-link ${
                    isActiveTab("/my-sessions") ? "bottom-tab-active" : ""
                  }`}
                  to={`/my-sessions`}
                >
                  <i class="fa fa-user tab-icons d-block mb-2"></i>
                  Profile
                </Link>
              </li>
            </ul>
          </nav>
        </>
      ) : (
        <>
          <nav className="navbar navbar-expand-md fixed-top">
            <div className="container-fluid container-fluid-p">
              <Link className="navbar-brand" to={`/dashboard`}>
                <img src={Logo} alt="logo" className="logo" width={"142"} />
              </Link>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <SearchBar placeholder="Search" onSubmit={handleSearch} />
                <ul className="navbar-nav mb-2 mb-md-0 d-flex">
                  {loggedIn && (
                    <>
                      <li className="nav-item me-3">
                        {isEmailVerified ? (
                          <>
                           <Link
                          className="nav-link bg-primary1 new-session-button"
                          to={`/create-session`}
                         
                        >
                          <i className="fa fa-plus"></i> New Session
                        </Link>
                        
                          </>
                        ): (
                          <>
                           <Link
                          className="nav-link bg-primary1 new-session-button"
                          to={"#"}
                          data-bs-target="#verifyEmail"
                          data-bs-toggle="modal"
                        >
                          <i className="fa fa-plus"></i> New Session
                        </Link>
                          </>
                        )}
                       
                      </li>

                      <li className="nav-item me-3">
                        {isEmailVerified ? (
                          <>
                           <Link
                          className="nav-link bg-secondary new-session-button"
                          to={`/invite-session`}
                         
                        >
                          <i className="fa fa-plus"></i> Invite Session
                        </Link>
                        
                          </>
                        ): (
                          <>
                           <Link
                          className="nav-link bg-primary1 new-session-button"
                          to={"#"}
                          data-bs-target="#verifyEmail"
                          data-bs-toggle="modal"
                        >
                          <i className="fa fa-plus"></i> Invite Session
                        </Link>
                          </>
                        )}
                       
                      </li>
                    </>
                  )}

                  {!loggedIn && (
                    <>
                      <li className="nav-item">
                        <a
                          className="nav-link bg-secondary"
                          href="javascript:void(0)"
                          onClick={() => (window.location.href = "/")}
                        >
                          Login
                        </a>
                      </li>
                    </>
                  )}
                </ul>

                {loggedIn && (
                  <>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 dropdown">
                        <Link
                          to="#"
                          className="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={
                              profile?.profileImageUrl === null ||
                              profile?.profileImageUrl === undefined
                                ? AvatarImage
                                : profile?.profileImageUrl
                            }
                            alt="mdo"
                            width="32"
                            height="32"
                            className="rounded-circle img-property-fit"
                          />
                        </Link>
                        <ul className="dropdown-menu text-small shadow">
                          <li>
                            <Link
                              className="dropdown-item profile-dropdown-item"
                              to={`/user-profile/${profile?.user_name}`}
                            >
                              <div className="row">
                                <div className="col-md-2 m-1 d-flex align-items-center justify-content-center">
                                  <span>
                                    <img
                                      src={
                                        profile?.profileImageUrl === null ||
                                        profile?.profileImageUrl === undefined
                                          ? AvatarImage
                                          : profile?.profileImageUrl
                                      }
                                      width="42"
                                      height="42"
                                      alt="userimage"
                                      className="rounded-circle img-property-fit"
                                      onError={handleUserImageError}
                                    />
                                  </span>
                                </div>
                                <div className="col-md-9">
                                  <strong>
                                    {isBrandUser
                                      ? profile?.brand_name
                                      : profile?.first_name +
                                        " " +
                                        profile?.last_name}
                                  </strong>
                                  <p className="mb-0">{profile?.email}</p>
                                  <p>{profile?.user_name}</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <hr className="dropdown-divider" />
                          </li>
                          {/* <li>
                            <Link className="dropdown-item border-bottom profile-dropdown-item py-2">
                              Settings
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              className="dropdown-item border-bottom profile-dropdown-item py-2"
                              to={"/change-password"}
                            >
                              Change Password
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item border-bottom profile-dropdown-item py-2"
                              to={"/my-bookmarks"}
                            >
                              My Bookmarks
                            </Link>
                          </li>
                          {/* <li>
                            <div className="d-flex">
                              <Link className="dropdown-item border-bottom profile-dropdown-item py-2 d-flex justify-content-between">
                                Language
                                <span>
                                  <i
                                    className="fa fa-globe"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </span>
                              </Link>
                            </div>
                          </li> */}
                          <li>
                            <a
                              className="dropdown-item py-2 profile-dropdown-item"
                              href="#"
                              onClick={() => handleLogout()}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </nav>
        </>
      )}

      <CreateSessionModal />
      <VerifyEmailModal email={profile?.email}/>
    </>
  );
};



export default Header;
