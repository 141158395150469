import React from 'react';

const CustomInput = ({ label, id, placeholder, value, onChange, error, touched, type, disabled }) => {
  return (
    <div>
      <label htmlFor={id} className='mb-2'>{label}</label>
      <input
        type={type}
        className="form-control"
        id={id}
        placeholder={placeholder}
        value={value}
        style={{ backgroundColor: disabled ? '#e1e1e1' : 'white' }}
        onChange={onChange}
        disabled={disabled}
      />
      {touched && error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default CustomInput;
