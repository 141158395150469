import { sendTakeSessionStatusApi } from "api/sessionApi";
import { getRequestSessionDetailApi } from "api/sessionApi";
import Layout from "layouts/Index";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AvatarImage from "../../assets/images/profile-placeholder.png";
import { formatGlobalDate } from "helpers/common_helper";
import { formatGlobalTime } from "helpers/common_helper";

const InviteSessionRequest = () => {
  const params = useParams();
  const requestId = params.request_id;
  const navigate = useNavigate();

  const [inviteSessionDetails, setInviteSessionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const getTakeSessionDetails = (sessionId) => {
    getRequestSessionDetailApi({ session_id: sessionId })
      .then((res) => {
        setInviteSessionDetails(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("inviteSessionRequestApi", err);
      });
  };

  const handleStatusUpdate = (status) => {
    let requestStatus;
    if (status === "MayBeLater") {
      requestStatus = "MayBeLater";
    } else if (status === "Ignored") {
      requestStatus = "Ignored";
    }

    sendTakeSessionStatusApi({ requestId: requestId, status: requestStatus })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate("/dashboard");
          }, 2000);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log("sendTakeSessionStatusApi", err);
      });
  };

  useEffect(() => {
    getTakeSessionDetails(requestId);
  }, [requestId]);

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Layout>
      <section className={`${isMobileView ? "main mt-3" : "main mt-5 py-5"}`}>
        <div className="container px-8">
          <div className="row">
            <div className="inner-block">
              <Link onClick={goBack}>
                <div className="icon-back mb-3">
                  {" "}
                  <i className="fa fa-arrow-left"></i>
                </div>
              </Link>

              <div
                className="card mob-card newmodal"
                style={{ marginBottom: isMobileView ? "8rem" : "3rem" }}
              >
                {loading ? (
                  <>
                    <div className="justify-content-center d-flex align-items-center">
                      <div className="loadingio-spinner-rolling-26k4myxj9b9">
                        <div className="ldio-y9y7x49yybs">
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card-body create-session-body">
                      <div className="sessin-info">
                        <div className="text-center mb-5">
                          <img
                            src={
                              inviteSessionDetails?.fromUser
                                ?.profileImageUrl === null
                                ? AvatarImage
                                : inviteSessionDetails?.fromUser
                                    ?.profileImageUrl
                            }
                            className="author-img rounded-circle img-property-fit"
                            alt="userimage"
                            width="50px"
                            height="50px"
                          />
                          <h5 className="mb-0">{`${inviteSessionDetails?.fromUser?.first_name} ${inviteSessionDetails?.fromUser?.last_name}`}</h5>
                          {/* <span className="desig">None</span> */}
                        </div>

                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div className="bg-gray">
                              <span>Session Title</span>
                              <p className="mb-0">
                                {inviteSessionDetails?.title}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <div className="bg-gray">
                              <span>Language for the session</span>
                              <p className="mb-0">
                                {inviteSessionDetails?.language?.name}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <div className="bg-gray">
                              <span>Preffered date</span>
                              <p className="mb-0">
                                {`${formatGlobalDate(
                                  inviteSessionDetails?.start_date_time
                                )}`}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <div className="bg-gray">
                              <span>Preffered Time</span>
                              <p className="mb-0">
                                {`${formatGlobalTime(
                                  inviteSessionDetails?.start_date_time
                                )} to ${formatGlobalTime(
                                  inviteSessionDetails?.end_date_time
                                )}`}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-12 mb-5">
                            <div className="bg-gray">
                              <span>Session Details</span>
                              <p className="mb-0">
                                {inviteSessionDetails?.description}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-12 mb-2">
                            <div className="d-flex align-items-center justify-content-center">
                              {inviteSessionDetails?.status === "Pending" && (
                                <>
                                  <div>
                                    <Link className="btn btn-create2">
                                      Create Session
                                    </Link>
                                  </div>
                                  <div>
                                    <Link
                                      className="btn btn-later2"
                                      onClick={() => {
                                        handleStatusUpdate("MayBeLater");
                                      }}
                                    >
                                      May be later
                                    </Link>
                                  </div>
                                  <div>
                                    <Link
                                      className="btn btn-ignore2"
                                      onClick={() => {
                                        handleStatusUpdate("Ignored");
                                      }}
                                    >
                                      Ignore
                                    </Link>
                                  </div>
                                </>
                              )}

                              {inviteSessionDetails?.status == "MayBeLater" && (
                                <>
                                  <div>
                                    <Link className="btn btn-later2">
                                      Scheduled For Later
                                    </Link>
                                  </div>
                                  <div>
                                    <Link className="btn btn-create2">
                                      Create Session
                                    </Link>
                                  </div>
                                </>
                              )}

                              {inviteSessionDetails?.status === "Ignored" && (
                                <>
                                  <div>
                                    <Link className="btn btn-ignore2">
                                      Session was ignored
                                    </Link>
                                  </div>
                                </>
                              )}

                              {inviteSessionDetails?.status === "Accepted" && (
                                <>
                                  <div>
                                    <Link className="btn btn-create2">
                                      Session Created
                                    </Link>
                                  </div>
                                </>
                              )}

                              {inviteSessionDetails?.status === "Rejected" && (
                                <>
                                  <div>
                                    <Link className="btn btn-ignore2">
                                      Session Rejected
                                    </Link>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
    </Layout>
  );
};

export default InviteSessionRequest;
