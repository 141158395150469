import React from "react";

const Header = () => {
  return (
    <header class="d-flex flex-wrap justify-content-center py-3 mb-0">
      <a
        href="/"
        class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
      >
        <img src="/assets/images/logo.svg" alt="Universal Manch" />
      </a>

      <ul class="nav">
        <li class="nav-item">
          <a href="/" class={`nav-link head-nav-link ${window.location.pathname === "/" ? "active": " "}`} aria-current="page">
            Home
          </a>
        </li>
        <li class="nav-item">
          <a href="/login" class={`btn login-btn-header nav-link head-nav-link ${window.location.pathname === "/stories" ? "active": " "}`}>
            Login
          </a>
        </li>
      </ul>
    </header>
  );
};

export default Header;
