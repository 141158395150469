import Layout from "layouts/Index";
import React, { useEffect, useState } from "react";
import AvatarImage from "../../assets/images/profile-placeholder.png";
import VMenuImage from "../../assets/images/v-menu.png";
import CustomTab from "components/ui/Common/CustomTab";
import SessionItemCard from "components/ui/Common/SessionItemCard";
import TopVerticalList from "components/ui/Common/TopVeticalList";
import EditProfileModal from "pages/Dashboard/Modal/EditProfileModal";
import { profileDetailsApi } from "api/profileApi";
import { getMySessionspi } from "api/sessionApi";
import * as url from "../../helpers/common_helper";
import { Link, useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { followUserApi } from "api/followApi";
import { ToastContainer, toast } from "react-toastify";
import RequestSessionModal from "pages/Dashboard/RequestSessionModal";
import ShareModal from "components/Utilities/ShareModal";
import createShortDynamicLink from "services/DynamicLink/shortLink";
import { logEvent } from "firebase/analytics";
import analytics from "index";
import ShareIcon from "../../assets/images/shareicon.svg";
import { useMediaQuery } from "react-responsive";
import NoDataImage from "../../assets/images/nodatafound.gif";
import NoSessionImage from "../../assets/images/no_session_image.png";

const UserProfile = () => {
  const { username } = useParams();
  const [userProfile, setUserProfile] = useState({});
  const [activeTab, setActiveTab] = useState("about-me");
  const [expanded, setExpanded] = useState(false);
  const [selectedSession, setSelectedSession] = useState(0);
  const [upcomingSessionDetails, setUpcomingSessionDetails] = useState([]);
  const [pastSessionDetails, setPastSessionDetails] = useState([]);
  const [dynamicLink, setDynamicLink] = useState("");
  const navigate = useNavigate();
  const userInfo = url.getUserInfo();
  const userId = userInfo?.id;

  const isLogged = url.isUserLoggedIn();

  const goBack = () => {
    navigate(-1);
    console.log("goBack");
  };

  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
    type: "upcoming",
    username: username,
  });

  const [activePastData, setActivePastData] = useState({
    page: 1,
    limit: 10,
    search: "",
    type: "past",
    username: username,
  });

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const [loading, setLoading] = useState(false);

  const handleReadMoreClick = () => {
    setExpanded(true);
  };

  const handleTabClick = (tabContent) => {
    setActiveTab(tabContent);
  };

  const getProfileDetails = () => {
    setLoading(true);
    profileDetailsApi({ username: username })
      .then((res) => {
        setUserProfile(res?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  

  useEffect(() => {
    getProfileDetails();
    logEvent(analytics, "user_profile_opened");
  }, [username]);

  useEffect(() => {
    getSessionDetails(activeData);
    getSessionPastDetails(activePastData);
  }, [activeData, activePastData]);

  const getSessionDetails = (activeData) => {
    getMySessionspi(activeData)
      .then((res) => {
        setUpcomingSessionDetails(res?.data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessionPastDetails = (activePastData) => {
    getMySessionspi(activePastData)
      .then((res) => {
        setPastSessionDetails(res?.data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFollow = (id) => {
    followUserApi({ username: username })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          getProfileDetails();
        } else if (!res?.data?.success) {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const topListData = [
    { value: userProfile?.sessionCount, label: "Sessions" },
    { value: userProfile?.followerCount, label: "Followers" },
    { value: userProfile?.followingCount, label: "Following" },
  ];

  const handleFetchMoreData = () => {
    setActiveData({ ...activeData, limit: activeData.limit + 3 });
    setActivePastData({ ...activePastData, limit: activePastData.limit + 3 });
  };

  const handleShareLink = () => {
    createShortDynamicLink("user-profile", username)
      .then((res) => {
        setDynamicLink(res?.shortLink);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <section className={`${isMobileView ? "main mt-3" : "main mt-5 py-5"}`}>
        <div className="container px-8">
          <div className="row">
            {loading ? (
              <div className="justify-content-center d-flex align-items-center">
                <div className="loadingio-spinner-rolling-26k4myxj9b9">
                  <div className="ldio-y9y7x49yybs">
                    <div></div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="inner-block">
                  <Link onClick={goBack}>
                    <div className="icon-back mb-3">
                      {" "}
                      <i className="fa fa-arrow-left"></i>
                    </div>
                  </Link>

                  <div className="card mob-card" style={{ marginBottom: isMobileView ? "2rem" : "3rem"}}>
                    <div className="card-header text-center bg-white pb-4">
                      <div className="text-center">
                        <div className="user-icon mx-auto">
                          <img
                            src={
                              userProfile?.profileImageUrl === null
                                ? AvatarImage
                                : userProfile?.profileImageUrl
                            }
                            className="rounded-circle img-property-fit"
                            alt="user"
                            width=""
                            height=""
                            onError={url.handleUserImageError}
                          />
                        </div>
                        <h5 className="username">{`${userProfile?.first_name} ${userProfile?.last_name} (${userProfile?.user_name})`}</h5>
                      </div>

                      {userId !== userProfile?.id ? (
                        <>
                          <div className="profile-edit">
                            <a
                              href=""
                              className="d-flex align-items-center"
                              data-bs-target="#shareModal"
                              data-bs-toggle="modal"
                              onClick={handleShareLink}
                            >
                              <i className="fa fa-share-alt me-2"></i>{" "}
                              <span className="mob-h">Share</span>
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          {isLogged && userId === userProfile?.id && (
                            <div className="profile-edit">
                              <Link
                                to={'/edit-profile'}
                                className="d-flex align-items-center"
                              >
                                <i className="fa fa-edit me-2"></i>{" "}
                                <span className="mob-h">Edit Profile</span>
                              </Link>
                            </div>
                          )}
                        </>
                      )}

                      <TopVerticalList statistics={topListData} />

                      {/* <HashtagList
                        hashtags={
                          userProfile &&
                          userProfile?.UserCategory?.map((item, index) => {
                            return item?.categoryName;
                          })
                        }
                      /> */}

                      {/* <p className="one-liner mt-3 mb-3">
                        {expanded
                          ? `${userProfile?.about_me || ""}`
                          : `${userProfile?.about_me?.substring(0, 80)}...`}
                        {!expanded && (
                          <a
                            href="#"
                            className="read-more-text"
                            onClick={handleReadMoreClick}
                          >
                            Read More
                          </a>
                        )}
                      </p> */}
                      {isLogged ? (
                        <>
                          {userProfile?.id !== userId ? (
                            <>
                              <div className="btn-list d-flex align-items-center justify-content-center pt-2 pb-2">
                                <div>
                                  <Link
                                    className="btn btn-request"
                                    data-bs-target="#requestSession"
                                    data-bs-toggle="modal"
                                  >
                                    <i className="fa fa-plus"></i> Request
                                    Session
                                  </Link>
                                </div>
                                {userProfile?.isFollowing === 1 ? (
                                  <div>
                                    <Link
                                      className="btn follow-btn"
                                      onClick={() =>
                                        handleFollow(userProfile?.id)
                                      }
                                    >
                                      <span>
                                        <i className="fa fa-check me-2"></i>
                                      </span>
                                      Following
                                    </Link>
                                  </div>
                                ) : (
                                  <div>
                                    <Link
                                      className="btn follow-btn"
                                      onClick={() =>
                                        handleFollow(userProfile?.id)
                                      }
                                    >
                                      Follow
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="btn-list d-flex align-items-center justify-content-center pt-2 pb-2">
                                <div className="my-share-button">
                                  <a
                                    href=""
                                    className="d-flex align-items-center"
                                    data-bs-target="#shareModal"
                                    data-bs-toggle="modal"
                                    onClick={handleShareLink}
                                  >
                                    <img
                                      src={ShareIcon}
                                      alt="shareicon"
                                      height=""
                                      width=""
                                      className="img-property-fit me-2"
                                    />{" "}
                                    <span className="mob-h">Share</span>
                                  </a>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="btn-list d-flex align-items-center justify-content-center pt-2 pb-2">
                            <div>
                              <Link
                                className="btn btn-request"
                                onClick={() => {
                                  window.location.href = "/login";
                                }}
                              >
                                <i className="fa fa-plus"></i> Request Session
                              </Link>
                            </div>
                            {userProfile?.isFollowing === 1 ? (
                              <div>
                                <Link
                                  className="btn follow-btn"
                                  onClick={() => {
                                    window.location.href = "/login";
                                  }}
                                >
                                  <span>
                                    <i className="fa fa-check me-2"></i>
                                  </span>
                                  Following
                                </Link>
                              </div>
                            ) : (
                              <div>
                                <Link
                                  className="btn follow-btn"
                                  onClick={() => {
                                    window.location.href = "/login";
                                  }}
                                >
                                  Follow
                                </Link>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <ul className="nav nav-tabs d-flex align-items-center justify-content-center mx-auto">
                  <CustomTab
                      active={activeTab === "about-me"}
                      tabContent="About Me"
                      onClick={() => handleTabClick("about-me")}
                    />
                    <CustomTab
                      active={activeTab === "upcoming-session"}
                      tabContent="Upcoming Sessions"
                      onClick={() => handleTabClick("upcoming-session")}
                    />
                    <CustomTab
                      active={activeTab === "past-session"}
                      tabContent="Past Sessions"
                      onClick={() => handleTabClick("past-session")}
                    />
                  </ul>

                  <div className="clearfix"></div>
                  <br />

                  <div className="tab-content">
                    {activeTab == "upcoming-session" && (
                      <InfiniteScroll
                        dataLength={upcomingSessionDetails?.length}
                        next={handleFetchMoreData}
                        hasMore={true}
                      >
                        <div
                          className="tab-pane container active"
                          id="upcoming-session"
                        >
                          {upcomingSessionDetails?.length === 0 ? (
                            <>
                              <div className="col-md-12 d-flex justify-content-center">
                                <div className="no-data">
                                  <img
                                    src={isLogged && userProfile?.id === userId ? NoSessionImage : NoDataImage}
                                    alt="no-data"
                                    width="300"
                                    height="300"
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="content-list">
                                <div className="row">
                                  {/* "10 May 2023, 12PM - 12:30PM" */}
                                  {upcomingSessionDetails?.map(
                                    (session, index) => (
                                      <SessionItemCard
                                        key={index}
                                        sessionId={session.id}
                                        P2Image={session.bannerImageUrl}
                                        UserImage={session.user.profileImageUrl}
                                        VMenuImage={VMenuImage}
                                        sessionTitle={session.title}
                                        abbreviation={
                                          userId !== userProfile?.id
                                            ? url.getSystemTimezoneAbbr()
                                            : session?.timezoneAbbreviation
                                        }
                                        sessionDateTime={
                                          userId !== userProfile?.id
                                            ? url.formatGlobalDateTime(
                                                session?.start_date_time
                                              )
                                            : url.formatGlobalDateTimeOwn(
                                                session?.start_date_time,
                                                session?.timezone
                                              )
                                        }
                                        userName={`${session.user.first_name} ${session.user.last_name}`}
                                        link={`/session-details/${session.id}`}
                                        
                                        isEditable={
                                          isLogged && userProfile?.id === userId
                                        }
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </InfiniteScroll>
                    )}

                    {activeTab == "past-session" && (
                      <InfiniteScroll
                        dataLength={pastSessionDetails?.length}
                        next={handleFetchMoreData}
                        hasMore={true}
                      >
                        <div
                          className="tab-pane container active"
                          id="past-session"
                        >
                          {pastSessionDetails?.length === 0 ? (
                            <>
                              <div className="col-md-12 d-flex justify-content-center">
                                <div className="no-data">
                                  <img
                                    src={NoDataImage}
                                    alt="no-data"
                                    width="300"
                                    height="300"
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="content-list">
                                <div className="row">
                                  {/* "10 May 2023, 12PM - 12:30PM" */}
                                  {pastSessionDetails?.map((session, index) => (
                                    <SessionItemCard
                                      key={index}
                                      sessionId={session.id}
                                      P2Image={session.bannerImageUrl}
                                      UserImage={session.user.profileImageUrl}
                                      VMenuImage={VMenuImage}
                                      sessionTitle={session.title}
                                      abbreviation={
                                        userId !== userProfile?.id
                                          ? url.getSystemTimezoneAbbr()
                                          : session?.timezoneAbbreviation
                                      }
                                      sessionDateTime={
                                        userId !== userProfile?.id
                                          ? url.formatGlobalDateTime(
                                              session?.start_date_time
                                            )
                                          : url.formatGlobalDateTimeOwn(
                                              session?.start_date_time,
                                              session?.timezone
                                            )
                                      }
                                      userName={`${session.user.first_name} ${session.user.last_name}`}
                                      link={`/session-details/${session.id}`}
                                      
                                      isEditable={
                                        isLogged && userProfile?.id === userId
                                      }
                                    />
                                  ))}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </InfiniteScroll>
                    )}

                    {activeTab == "about-me" && (

                      <div
                        className="tab-pane container active"
                        id="about-me"
                      >
                        <div className="content-list">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="about-me">
                              
                              <div dangerouslySetInnerHTML={{ __html: userProfile?.about_me}}>
                                
                                </div>
                             
                            </div>
                          </div>
                        </div>
                        </div>
                        </div>
                    )}
                    
                  </div>
                </div>
              </>
            )}
          </div>
          <ToastContainer />
        </div>
      </section>
      <EditProfileModal />
      <RequestSessionModal userID={userProfile?.id} />
      <ShareModal shareableLink={dynamicLink} />
      {/* <CreateSessionModal /> */}
    </Layout>
  );
};

export default UserProfile;
