import { getAccessTokenApi } from "api/authApi";
import axios from "axios";

const apiKey = "AIzaSyDgSPJ5EW68tWZ--B-X9xLVzNvdAhOSn0c";

async function createShortDynamicLink(path, payloadId ) {
  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`;

  const getAccessToken = await getAccessTokenApi();

  let googleToken = getAccessToken?.data?.data?.token;

  const response = await axios.post(url, {
    dynamicLinkInfo: {
      domainUriPrefix: `https://link.universalmanch.com`,
      link: `https://universalmanch.com/${path}/${payloadId}`,
      androidInfo: {
        androidPackageName: "com.iloma.learnlive.learn_live",
        androidFallbackLink: `https://universalmanch.com/${path}/${payloadId}`,
      },
      iosInfo: {
        iosBundleId: "com.iloma.letslearnlive",
        iosFallbackLink: `https://universalmanch.com/${path}/${payloadId}`,
      },
      navigationInfo: {
        enableForcedRedirect: true,
      },
      // suffix: { 
      //   option: "SHORT",
      // },

    }
  }, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${googleToken}`,
    },
  });

  return response.data;
}



  export default createShortDynamicLink;
