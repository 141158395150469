
import Login from 'pages/Authentication/Login';
import { Route, Routes, Navigate } from 'react-router-dom';
import Register from 'pages/Authentication/Register';
import Dashboard from 'pages/Dashboard/Home';
import MySession from 'pages/MySession/Index';
import MyJoinList from 'pages/MyJointList/Index';
import SessionDetail from 'pages/MySession/SessionDetail';
import React, { useEffect } from 'react';
import { checkOnboardingApi } from 'api/profileApi';
import UserRequest from 'pages/UserRequest/Index';
import UserProfile from 'pages/UserProfile/Index';
import AuthVerify from 'helpers/AuthVerify';
import UpdatePassword from 'pages/Authentication/UpdatePassword';
import CMSPage from 'pages/CMS/Cmspage';
import { logEvent } from "firebase/analytics";
import analytics from 'index';
import Notification from 'pages/Notification/Index';
import Bookmark from 'pages/Bookmark/Index';
import ChangePassword from 'pages/Authentication/ChangePassword';
import Home from 'pages/Home/HomeIndex';
import Stories from 'pages/Home/Stories/Index';
import EmailVerification from 'pages/Authentication/EmailVerification';
import StoryList from 'pages/Home/Stories/StoryList';
import SessionDetailPage from 'pages/Dashboard/SessionDetailPage';
import EditProfilePage from 'pages/Dashboard/EditProfilePage';
import CreateSessionPage from 'pages/Dashboard/CreateSessionPage';
import InviteSessionPage from 'pages/Dashboard/InviteSessionPage';
import InviteSessionRequest from 'pages/UserRequest/InviteSessionRequest';


function App() {

  const checkOnboarding = () => {
    checkOnboardingApi().then((res) => {
      if(!res?.data?.data?.isUserDetailsUpdated){
        window.$(document).ready(function () {
          window.$('#myinterest').modal('show');
        });
      }

    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    if(localStorage.getItem('_lluser')){
    checkOnboarding();
    }
    logEvent(analytics, 'desktop_app_opened');
  }, [])

  const logOut = () => {
    localStorage.clear();
    window.location.href = '/';
  }


  
  return (
    <>
    <Routes>
      {!localStorage.getItem('_lluser') ? (
      <>
         <Route exact path="/" element={<Home/>} />
         <Route exact path='/story-details/:id?' element={<Stories/>}/>
         <Route exact path='/stories' element={<StoryList/>}/>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path='/update-password' element={<UpdatePassword/>}/>
            <Route exact path="/dashboard/:session_id?" element={<Dashboard />} />
            <Route exact path='/user-profile/:username' element={<UserProfile/>}/>
            <Route exact path='/email-verification/:token' element={<EmailVerification/>}/>
            <Route exact path='/session-info/:id' element={<SessionDetailPage/>}/>
            <Route exact path='/session-details/:id' element={<SessionDetailPage/>}/>
            <Route
              exact
              path="*"
              element={<Navigate to="/" replace />}
            ></Route>
            
          

      </>
      ) : (
        <>
         <Route exact path="/" element={<Navigate to="/dashboard" />} />
            <Route exact path="/login" element={<Navigate to="/dashboard" />} />
            <Route exact path="/dashboard/:session_id?" element={<Dashboard />} />
            <Route exact path="/my-sessions" element={<MySession />} />
            <Route exact path="/my-join-list" element={<MyJoinList />} />
            <Route exact path="/session-details/:id" element={<SessionDetail />} />
            <Route exact path='/user-requests' element={<UserRequest/>}/>
            <Route exact path='/user-profile/:username' element={<UserProfile/>}/>
            <Route exact path='/notifications' element={<Notification/>}/>
            <Route exact path='/my-bookmarks' element={<Bookmark/>}/>
            <Route exact path='/change-password' element={<ChangePassword/>}/>
            <Route exact path='/email-verification/:token' element={<EmailVerification/>}/>
            <Route exact path='/session-info/:id' element={<SessionDetailPage/>}/>
            <Route exact path='/edit-profile' element={<EditProfilePage/>}/>
            <Route exact path='/create-session' element={<CreateSessionPage/>}/>
            <Route exact path='/invite-session' element={<InviteSessionPage/>}/>
            <Route exact path='/invite-session-request/:request_id' element={<InviteSessionRequest/>}/>
            {/* <Route exact path='/session-details/:id' element={<UpdatePassword/>}/> */}
            <Route exact path="*" element={<Navigate to="/dashboard" replace />} />
        </>
      )
      }
        <Route exact path='/page' element={<CMSPage/>}/>
    </Routes>
    <AuthVerify logOut={logOut}/>
    </>
  )
}

export default App;

