import ProfileInfo from "components/ui/Common/ProfileInfo";
import Layout from "layouts/Index";
import SidebarMenu from "layouts/SidebarMenu";
import ProfileHeader from "pages/Dashboard/ProfileHeader";
import React, { useEffect, useState } from "react";
import CustomTab from "components/ui/Common/CustomTab";
import UserRequestCard from "components/ui/Common/UserRequestCard";
import TakeSessionCard from "components/ui/Common/TakeSessionCard";
import { getRequestedSessionsApi } from "api/sessionApi";
import { acceptRejectRequestApi } from "api/sessionApi";
import { ToastContainer, toast } from "react-toastify";
import EditProfileModal from "pages/Dashboard/Modal/EditProfileModal";
import TakeSessionModal from "./Modal/TakeSessionModal";
import { getTakeSessionsApi } from "api/sessionApi";
import { newParticipantRequestStatus } from "data/selectoptions";
import { takeSessionRequestStatus } from "data/selectoptions";
import InfiniteScroll from "react-infinite-scroll-component";
import { UTCDateFormat } from "helpers/common_helper";
import { UTCTimeFormat } from "helpers/common_helper";
import { connect } from "react-redux";
import { fetchProfile } from "actions/profileActions";
import { UTCDateTimeFormatWithUnderscore } from "helpers/common_helper";
import { useDispatch } from "react-redux";
import { userRequestCountApiTrigger } from "actions/userRequestActions";
import { isUserLoggedIn } from "helpers/common_helper";
import AvatarImage from "../../assets/images/profile-placeholder.png";
import { logEvent } from "firebase/analytics";
import analytics from "index";
import NoDataImage from "../../assets/images/nodatafound.gif";
import { useMediaQuery } from "react-responsive";
import { formatGlobalDateTime } from "helpers/common_helper";
import { formatGlobalTime } from "helpers/common_helper";
import { Link } from "react-router-dom";

const UserRequest = ({ profile, error, fetchProfile, sessionApiTriggered }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("new-participant-request");
  const [requestSessionList, setRequestSessionList] = useState([]);
  const [selectedParticipantStatus, setSelectedParticipantStatus] =
    useState("All");
  const [selectedTakeSessionStatus, setSelectedTakeSessionStatus] =
    useState("All");
  const [loading, setLoading] = useState(false);
  const [newParticipantReqCount, setNewParticipantReqCount] = useState(null);
  const [takeSessionReqCount, setTakeSessionReqCount] = useState(null);
  const [onViewTakeSessionId, setOnViewTakeSessionId] = useState("");
  const [takeSessionList, setTakeSessionList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [takeHasMore, setTakeHasMore] = useState(true);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 4,
    search: "",
    type: "",
  });

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const [activeTakeSessionData, setActiveTakeSessionData] = useState({
    page: 1,
    limit: 10,
    search: "",
    filter: {
      status: "All",
    },
  });

  const handleTabClick = (tabContent) => {
    setActiveTab(tabContent);
  };

  const profileTopList = [
    {
      title: "Sessions",
      value: profile?.sessionCount,
    },
    {
      title: "Followers",
      value: profile?.followerCount,
    },
    {
      title: "Following",
      value: profile?.followingCount,
    },
  ];

  const profileTopListUnknown = [
    {
      title: "Sessions",
      value: 0,
    },
    {
      title: "Followers",
      value: 0,
    },
    {
      title: "Following",
      value: 0,
    },
  ];

  const getRequestSessionList = (data) => {
    setLoading(true);
    getRequestedSessionsApi(data)
      .then((res) => {
        setRequestSessionList(res?.data?.data?.rows);
        setNewParticipantReqCount(res?.data?.pendingCount);
        setActiveData({
          ...activeData,
          limit: activeData.limit + 4,
        });

        if (res?.data?.data?.rows?.length <= 0) {
          setHasMore(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRequestSessionListPagination = (requestData, takeSessionData) => {
    if (activeTab === "new-participant-request") {
      getRequestedSessionsApi(requestData)
        .then((res) => {
          setRequestSessionList(res?.data?.data?.rows);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (activeTab === "take-session") {
      getTakeSessionsApi(takeSessionData)
        .then((res) => {
          setTakeSessionList(res?.data?.data?.requestSessions);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const acceptRejectRequest = (data) => {
    acceptRejectRequestApi(data)
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          getRequestSessionList(activeData);
          getTakeSessionsList(activeTakeSessionData);
          dispatch(userRequestCountApiTrigger(true));
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTakeSessionsList = (data) => {
    setLoading(true);
    getTakeSessionsApi(data)
      .then((res) => {
        setTakeSessionList(res?.data?.data?.requestSessions);
        setTakeSessionReqCount(res?.data?.data?.count);

        if (res?.data?.data?.requestSessions?.length <= 0) {
          setTakeHasMore(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchProfile();
    logEvent(analytics, "user_request_opened");
  }, []);

  useEffect(() => {
    getRequestSessionList(activeData);
    getTakeSessionsList(activeTakeSessionData);
  }, [selectedParticipantStatus, selectedTakeSessionStatus]);

  useEffect(() => {
    if (sessionApiTriggered) {
      getRequestSessionList(activeData);
      getTakeSessionsList(activeTakeSessionData);
    }
  }, [sessionApiTriggered]);

  const handleFetchMoreData = () => {
    const page = Math.ceil(requestSessionList.length / 4) + 1; // Calculate the next page number

    setActiveData({
      ...activeData,
      limit: activeData.limit + 4,
    });

    setActiveTakeSessionData({
      ...activeTakeSessionData,
      limit: activeTakeSessionData.limit + 4,
    });

    getRequestSessionListPagination(activeData, activeTakeSessionData);
  };

  return (
    <Layout>
      <section className={`${isMobileView ? "main mt-3" : "main mt-5 py-5"}`}>
        <div className="container-fluid container-fluid-p">
          <div className="row">
          {!isMobileView && (
                <div
                className={`col-md-2 pt-5 left-block sticky-sidebar ${
                  isUserLoggedIn() ? "" : "locked"
                }`}
              >
                <SidebarMenu />
              </div>
              )}
            <div className="col-md-10 right-block">
              <div className="mb-4">
                <ul className="nav nav-tabs d-flex align-items-center justify-content-center mx-auto">
                  <CustomTab
                    active={activeTab === "new-participant-request"}
                    tabContent="New Participant Request"
                    onClick={() => handleTabClick("new-participant-request")}
                    count={newParticipantReqCount > 0 ? newParticipantReqCount : ""}
                  />
                  <CustomTab
                    active={activeTab === "take-session"}
                    tabContent="Take Session"
                    onClick={() => handleTabClick("take-session")}
                    count={takeSessionReqCount > 0 ? takeSessionReqCount : ""}
                  />
                </ul>

                <div className="clearfix"></div>
                <br />

                <div className="tab-content mobile-tab-content">
                  {activeTab === "new-participant-request" && (
                    <>
                      {loading ? (
                        <>
                          <div className="justify-content-center d-flex align-items-center">
                            <div className="loadingio-spinner-rolling-26k4myxj9b9">
                              <div className="ldio-y9y7x49yybs">
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <InfiniteScroll
                            dataLength={
                              requestSessionList?.length > 0
                                ? requestSessionList?.length
                                : 0
                            }
                            next={handleFetchMoreData}
                            hasMore={hasMore}
                            // loader={<h4>Loading...</h4>}
                            // endMessage={
                            //   <p style={{ textAlign: 'center' }}>
                            //     <b>Yay! You have seen it all</b>
                            //   </p>
                            // }
                          >
                            <div
                              className="tab-pane container active"
                              id="New-Participant-Request"
                            >
                              <div className="row">
                                <div className="col-md-2 offset-md-10 mb-4 pr-0">
                                  <div className="dropdown">
                                    <select
                                      className="btn btn-drop dropdown-toggle"
                                      placeholder="Select"
                                      style={{ textAlign: "left" }}
                                      onChange={(e) => {
                                        setSelectedParticipantStatus(
                                          e.target.value
                                        );
                                        setActiveData({
                                          ...activeData,
                                          type: e.target.value,
                                        });
                                      }}
                                      disabled={loading}
                                      value={selectedParticipantStatus}
                                    >
                                      {Object.entries(
                                        newParticipantRequestStatus
                                      ).map(([key, value]) => (
                                        <option key={key} value={value}>
                                          {key}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-12 pr-0">
                                  {requestSessionList &&
                                  requestSessionList?.length > 0 ? (
                                    requestSessionList?.map(
                                      (requestSession, index) => (
                                        <UserRequestCard
                                          requestId={requestSession?.id}
                                          status={requestSession?.status}
                                          title={requestSession?.session?.title}
                                          // datetime={`${UTCDateFormat(
                                          //   requestSession?.session
                                          //     ?.start_date_time
                                          // )}, ${UTCTimeFormat(
                                          //   requestSession?.session
                                          //     ?.start_date_time
                                          // )} - ${UTCTimeFormat(
                                          //   requestSession?.session
                                          //     ?.end_date_time
                                          // )}`}
                                          datetime={`${formatGlobalDateTime(requestSession?.session?.start_date_time)} - ${formatGlobalTime(requestSession?.session?.end_date_time)}`}
                                          username={`${requestSession?.participantUser?.first_name} ${requestSession?.participantUser?.last_name}`}
                                          userimage={
                                            requestSession?.participantUser
                                              ?.profileImageUrl
                                          }
                                          onAccept={(requestId) => {
                                            acceptRejectRequest({
                                              requestId: requestId,
                                              type: "Accepted",
                                            });
                                          }}
                                          onReject={(requestId) => {
                                            acceptRejectRequest({
                                              requestId: requestId,
                                              type: "Rejected",
                                            });
                                          }}
                                          userprofileName={
                                            requestSession?.participantUser
                                              ?.user_name
                                          }
                                        />
                                      )
                                    )
                                  ) : (
                                    <div className="col-md-12 d-flex justify-content-center">
                                      <div className="no-data">
                                        <img
                                          src={NoDataImage}
                                          alt="no-data"
                                          width="400"
                                          height="400"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </InfiniteScroll>
                        </>
                      )}
                    </>
                  )}

                  {activeTab === "take-session" && (
                    <>
                      {loading ? (
                        <>
                          <div className="justify-content-center d-flex align-items-center">
                            <div className="loadingio-spinner-rolling-26k4myxj9b9">
                              <div className="ldio-y9y7x49yybs">
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <InfiniteScroll
                            dataLength={
                              takeSessionList?.length > 0
                                ? takeSessionList?.length
                                : 0
                            }
                            next={handleFetchMoreData}
                            hasMore={takeHasMore}
                            // loader={<h4>Loading...</h4>}
                          >
                            <div
                              className="tab-pane container active"
                              id="Take-Session"
                            >
                              <div className="row">
                                <div className="col-md-2 offset-md-10 mb-4 pr-0">
                                  <div className="dropdown">
                                    <select
                                      className="btn btn-drop dropdown-toggle"
                                      placeholder="Select"
                                      style={{ textAlign: "left" }}
                                      onChange={(e) => {
                                        setSelectedTakeSessionStatus(
                                          e.target.value
                                        );
                                        setActiveTakeSessionData({
                                          ...activeTakeSessionData,
                                          filter: {
                                            status: e.target.value,
                                          },
                                        });
                                      }}
                                      disabled={loading}
                                      value={selectedTakeSessionStatus}
                                    >
                                      {Object.entries(
                                        takeSessionRequestStatus
                                      ).map(([key, value]) => (
                                        <option key={key} value={key}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-12 pr-0">
                                  {takeSessionList &&
                                  takeSessionList?.length > 0 ? (
                                    takeSessionList?.map(
                                      (takeSession, index) => (
                                        <TakeSessionCard
                                          key={index}
                                          sessionId={takeSession?.id}
                                          title={takeSession?.title}
                                          username={`${takeSession?.fromUser?.first_name} ${takeSession?.fromUser?.last_name}`}
                                          userimage={
                                            takeSession?.fromUser
                                              ?.profileImageUrl
                                          }
                                          description={takeSession?.description}
                                         
                                          userprofileName={
                                            takeSession?.fromUser?.user_name
                                          }
                                          sessionStatus={
                                            takeSession?.status == "MayBeLater"
                                              ? "May be later"
                                              : takeSession?.status
                                          }
                                          // datetime={UTCDateTimeFormatWithUnderscore(
                                          //   takeSession?.created_at
                                          // )}
                                          datetime={formatGlobalDateTime(takeSession?.created_at)}
                                        />
                                      )
                                    )
                                  ) : (
                                    <div className="col-md-12 d-flex justify-content-center">
                                      <div className="no-data">
                                        <img
                                          src={NoDataImage}
                                          alt="no-data"
                                          width="400"
                                          height="400"
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {/* <TakeSessionCard
                              title={"Figma Basic"}
                              username={"Amol Hirkane"}
                              userimage={null}
                              description={"Hello Parth"}
                            /> */}
                                </div>
                              </div>
                            </div>
                          </InfiniteScroll>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
      {/* <CreateSessionModal/> */}
      <EditProfileModal />
      <TakeSessionModal sessionId={onViewTakeSessionId} />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    loading: state.profile.loading,
    error: state.profile.error,
    sessionApiTriggered: state.userRequest.sessionApiTriggered,
  };
};

const mapDispatchToProps = {
  fetchProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRequest);
