import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/images/icon_close.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "components/ui/Common/FormInput";
import { profileDetailsApi } from "api/profileApi";
import { updateProfileApi } from "api/profileApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLanguageApi } from "api/sessionApi";
import { getCategoriesApi } from "api/categoryApi";
import Select from "react-select";
import { updateProfileImageApi } from "api/profileApi";
import { connect } from "react-redux";
import { fetchProfile } from "actions/profileActions";
import AvatarImage from "../../../assets/images/profile-placeholder.png";
import TimezoneSelect from "elements/TimezoneSelect";
import { getTimezoneAbbr } from "helpers/common_helper";

const EditProfileModal = ({ profile, error, fetchProfile }) => {
  const [file, setFile] = useState(null);
  const [userProfile, setUserProfile] = useState({});
  const userInfo = localStorage.getItem("_lluser");
  const username = JSON.parse(userInfo)?.user_name;

  const [selectedUserType, setSelectedUserType] = useState(null);
  const [stringTimezone, setStringTimezone] = useState("");

  const [languageList, setLanguageList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });

  const getLanguageList = (data) => {
    getLanguageApi(data)
      .then((res) => {
        setLanguageList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategoryList = (data) => {
    getCategoriesApi(data)
      .then((res) => {
        setCategoryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      country_phone_code: "",
      about_me: "",
      categoryIds: [],
      languageIds: [],
      user_name: "",
      is_brand: null,
      brand_name: "",
      timezone_id: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      about_me: Yup.string()
        .min(100, "About me must have max 100 words")
        .required("About Me is required"),
      categoryIds: Yup.array()
        .min(2, "Interests must have 2 items")
        .required("Interest is required"),
      languageIds: Yup.array()
        .min(1, "Languages must have 1 item")
        .required("Language is required"),
      user_name: Yup.string().required("Username is required"),
      is_brand: Yup.boolean().required("Please select user type"),
      brand_name: Yup.string().test(
        "brand_name",
        "Brand name is required",
        function (value) {
          const { is_brand } = this.parent;
          if (is_brand && !value) {
            return false;
          } else {
            return true;
          }
        }
      ),
      timezone_id: Yup.string().required("Timezone is required"),
    }),
    onSubmit: (values) => {
      updateProfileApi(values)
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setIsSubmitted(true);
            // update values in local storage only is_brand and brand_name
            const userInfo = localStorage.getItem("_lluser");
            const userInfoObj = JSON.parse(userInfo);
            userInfoObj.is_brand = values.is_brand;
            userInfoObj.brand_name = values.brand_name;
            localStorage.setItem("_lluser", JSON.stringify(userInfoObj));
            localStorage.setItem("_lltimezone", stringTimezone);
            getTimezoneAbbr(stringTimezone);

            setTimeout(() => {
              window.$("#editProfile").modal("hide");
            }, 1500);
            fetchProfile();
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleClearAll = () => {
    formik.resetForm();
  };

  const getProfileDetails = async () => {
    profileDetailsApi({ username: username })
      .then((res) => {
        setUserProfile(res?.data?.data);
        setFieldValue("first_name", res?.data?.data?.first_name);
        setFieldValue("last_name", res?.data?.data?.last_name);
        setFieldValue("email", res?.data?.data?.email);
        setFieldValue("about_me", res?.data?.data?.about_me);
        setFieldValue("user_name", res?.data?.data?.user_name);

        const timezoneId = res?.data?.data?.TimeZone?.id;
        const timezoneUtcOffset = res?.data?.data?.TimeZone?.utc_offset;
        const timezone = res?.data?.data?.TimeZone?.timezone;
        
        const timezoneObj = {
          value: timezoneId,
          label: `GMT ${timezoneUtcOffset} - ${timezone}`,
        }

        setSelectedTimezone(timezoneObj);
        const timezone_id = res?.data?.data?.timezone_id;
        setFieldValue("timezone_id", timezone_id);

        const isBrand = res?.data?.data?.is_brand;
        const brandValue = res?.data?.data?.brand_name;

        if (isBrand === 1) {
          setFieldValue("is_brand", 1);
          setFieldValue("brand_name", brandValue);
          setSelectedUserType("Brand");
        } else {
          setFieldValue("is_brand", 0);
          setSelectedUserType("Individual");
        }

        const category = res?.data?.data?.UserCategory?.map((item, index) => {
          return { value: item?.category_id, label: item?.categoryName };
        });
        setSelectedCategory(category);

        const language = res?.data?.data?.UserLanguage?.map((item, index) => {
          return { value: item?.language_id, label: item?.languageName };
        });

        setFieldValue(
          "languageIds",
          language?.map((item) => item?.value)
        );
        setFieldValue(
          "categoryIds",
          category?.map((item) => item?.value)
        );

        if (res?.data?.data?.profileImageUrl === null) {
          setFile(AvatarImage);
        }

        setSelectedLanguage(language);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileDetails();
    getLanguageList(activeData);
    getCategoryList(activeData);
  }, []);

  const handleProfileImageUpload = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));

    const formData = new FormData();
    formData.append("profile_image", e.target.files[0]);
    updateProfileImageApi(formData)
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetchProfile();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTextAreaChange = (event) => {
    setFieldValue("about_me", event.target.value);
    adjustTextAreaHeight(event.target);
  };

  const adjustTextAreaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleChangeUserOption = (e) => {
    setSelectedUserType(e.target.value);
    if (e.target.value === "Brand") {
      formik.setFieldValue("is_brand", 1);
    } else {
      formik.setFieldValue("is_brand", 0);
    }
  };

  const handleSelectTimezone = (selectedOption) => {
    const plainTimezone = selectedOption?.plainValue;
    setStringTimezone(plainTimezone);
    const selectedValue = selectedOption?.value;
    setSelectedTimezone(selectedOption);
    setFieldValue("timezone_id", selectedValue);
  };

  return (
    <div className="modal fade newmodal" id="editProfile">
      <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header text-center">
            <button type="button" className="btn-close" data-bs-dismiss="modal">
              <img src={CloseIcon} alt="" width="" height="" />
            </button>
            <h4 className="text-center">Edit Profile</h4>
          </div>
          <div className="modal-body px-5 py-4">
            <form>
              <div className="row">
                <div className="text-center mb-4">
                  <div className="position-relative d-inline-block">
                    <img
                      src={file ? file : userProfile?.profileImageUrl}
                      alt="Profile Image"
                      className="profile-image rounded-circle img-property-fit"
                      height="100px"
                      width="100px"
                    />
                    <div className="edit-icon">
                      <label htmlFor="file-input" className="file-input-label">
                        <i className="fa fa-edit"></i>
                        <input
                          id="file-input"
                          type="file"
                          className="d-none"
                          onChange={handleProfileImageUpload}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <FormInput
                    name="first_name"
                    type="text"
                    label="First Name"
                    placeholder="First Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name || ""}
                    error={errors.first_name}
                    touched={touched.first_name}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    name="last_name"
                    type="text"
                    label="Last Name"
                    placeholder="Last Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name || ""}
                    error={errors.last_name}
                    touched={touched.last_name}
                  />
                </div>
                <div className="col-md-12">
                  <FormInput
                    name="email"
                    type="email"
                    label="Email"
                    isDisabled={true}
                    placeholder="Enter a valid email address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email || ""}
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>

                <div className="col-md-12">
                  <FormInput
                    name="user_name"
                    type="text"
                    label="Username"
                    isDisabled={true}
                    placeholder="Enter a valid username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user_name || ""}
                    error={errors.user_name}
                    touched={touched.user_name}
                  />
                </div>

                <div className="col-md-12">
                  <div className="form-outline mb-3">
                    <label className="form-label me-4" for="form3Example3">
                      I am a
                    </label>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="Individual"
                        onChange={handleChangeUserOption}
                        style={{ padding: "unset", marginTop: "1px" }}
                        checked={selectedUserType == "Individual"}
                      />
                      <label className="form-check-label" for="inlineRadio1">
                        Individual
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value="Brand"
                        onChange={handleChangeUserOption}
                        style={{ padding: "unset", marginTop: "1px" }}
                        checked={selectedUserType == "Brand"}
                      />
                      <label className="form-check-label" for="inlineRadio2">
                        Brand
                      </label>
                    </div>

                    {formik.touched.is_brand && formik.errors.is_brand ? (
                      <div className="text-danger">
                        {formik.errors.is_brand}
                      </div>
                    ) : null}
                  </div>
                </div>

                {selectedUserType == "Brand" && (
                  <>
                    <div className="col-md-12">
                      <FormInput
                        name="brand_name"
                        type="text"
                        label="Brand"
                        placeholder="Enter a valid brand name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.brand_name || ""}
                        error={errors.brand_name}
                        touched={touched.brand_name}
                      />
                    </div>
                  </>
                )}

                <div className="col-md-12">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      Interests (Minimum 2)
                    </label>
                    <Select
                      name="categoryIds"
                      className=""
                      id="multiple-select-field6"
                      placeholder="Select Topics"
                      isMulti
                      options={categoryList?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption.map(
                          (item) => item.value
                        );
                        setSelectedCategory(selectedOption);
                        setFieldValue("categoryIds", selectedValue);
                      }}
                      value={selectedCategory || []}
                    />
                    {errors.categoryIds && touched.categoryIds && (
                      <div className="text-danger">{errors.categoryIds}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      Languages (Minimum 2)
                    </label>
                    <Select
                      name="languageIds"
                      className=""
                      id="multiple-select-field6"
                      placeholder="Select Languages"
                      isMulti
                      options={languageList?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      // onChange={(selectedOption) =>
                      //   setFieldValue("languages", selectedOption)
                      // }
                      onChange={(e) => {
                        const f = e.map((item, index) => {
                          return item.value;
                        });
                        setFieldValue("languageIds", f);
                        setSelectedLanguage(e);
                      }}
                      value={selectedLanguage}
                    />
                    {errors.languageIds && touched.languageIds && (
                      <div className="text-danger">{errors.languageIds}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      Timezone
                    </label>
                    <TimezoneSelect
                      timezoneList={timezoneList}
                      handleSelectTimezone={handleSelectTimezone}
                      selectedTimezone={selectedTimezone || []}
                    />

                    {errors.timezone_id && touched.timezone_id && (
                      <div className="text-danger">{errors.timezone_id}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-outline mb-4">
                    <label className="form-label mb-0" htmlFor="">
                      About Me
                    </label>
                    <textarea
                      className="form-control"
                      name="about_me"
                      onChange={handleTextAreaChange}
                      type="textarea"
                      onBlur={handleBlur}
                      value={values.about_me || ""}
                      autoComplete="off"
                    ></textarea>
                    {errors.about_me && touched.about_me && (
                      <div className="text-danger">{errors.about_me}</div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer justify-content-center">
            <a
              onClick={handleSubmit}
              className="btn btn-login btn-lg btn-block w-100 btn-submit"
            >
              Update
            </a>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    loading: state.profile.loading,
    error: state.profile.error,
  };
};

const mapDispatchToProps = {
  fetchProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModal);
