import Layout from "layouts/Index";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getLanguageApi } from "api/sessionApi";
import { getCategoriesApi } from "api/categoryApi";
import { updateProfileImageApi } from "api/profileApi";
import { ToastContainer, toast } from "react-toastify";
import { profileDetailsApi } from "api/profileApi";
import AvatarImage from "../../assets/images/profile-placeholder.png";
import { useDispatch } from "react-redux";
import { fetchProfile } from "actions/profileActions";
import CustomInput from "components/ui/Common/CustomInput";
import Select from "react-select";
import TimezoneSelect from "elements/TimezoneSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getTimezoneAbbr } from "helpers/common_helper";
import { updateProfileApi } from "api/profileApi";
import { setDashboardApiTriggered } from "actions/DashboardActions";
import { getSystemSettingsApi } from "api/systemSettingApi";

const EditProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [userProfile, setUserProfile] = useState({});
  const userInfo = localStorage.getItem("_lluser");
  const username = JSON.parse(userInfo)?.user_name;

  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });

  const [selectedUserType, setSelectedUserType] = useState(null);
  const [stringTimezone, setStringTimezone] = useState("");
  const [stringAbbreviation, setStringAbbreviation] = useState("");

  const [languageList, setLanguageList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [maxAboutUsLimit, setMaxAboutUsLimit] = useState(500);
  const [minAboutUsLimit, setMinAboutUsLimit] = useState(100);
  const [activeData, setActiveData] = useState({
    page: 1,
    limit: 10,
    search: "",
  });

  const getLanguageList = (data) => {
    getLanguageApi(data)
      .then((res) => {
        setLanguageList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategoryList = (data) => {
    getCategoriesApi(data)
      .then((res) => {
        setCategoryList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSystemSettingList = () => {
    getSystemSettingsApi()
      .then((res) => {
        if(res?.data?.success){
          if(res?.data?.data?.MAX_ABOUT_US_LIMIT !== null || res?.data?.data?.MAX_ABOUT_US_LIMIT !== undefined || res?.data?.data?.MAX_ABOUT_US_LIMIT !== " "){
          setMaxAboutUsLimit(res?.data?.data?.MAX_ABOUT_US_LIMIT);
          }
          if(res?.data?.data?.MIN_ABOUT_US_LIMIT !== null || res?.data?.data?.MIN_ABOUT_US_LIMIT !== undefined || res?.data?.data?.MIN_ABOUT_US_LIMIT !== " "){
          setMinAboutUsLimit(res?.data?.data?.MIN_ABOUT_US_LIMIT);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      country_phone_code: "",
      about_me: "",
      categoryIds: [],
      languageIds: [],
      user_name: "",
      is_brand: null,
      brand_name: "",
      timezone_id: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      about_me: Yup.string()
        .min(minAboutUsLimit, `About me must have min ${minAboutUsLimit} words`)
        .max(maxAboutUsLimit, `About me must have max ${maxAboutUsLimit} words`)
        .required("About Me is required"),
      categoryIds: Yup.array()
        .min(2, "Interests must have 2 items")
        .required("Interest is required"),
      languageIds: Yup.array()
        .min(1, "Languages must have 1 item")
        .required("Language is required"),
      user_name: Yup.string().required("Username is required"),
      is_brand: Yup.boolean().required("Please select user type"),
      brand_name: Yup.string().test(
        "brand_name",
        "Brand name is required",
        function (value) {
          const { is_brand } = this.parent;
          if (is_brand && !value) {
            return false;
          } else {
            return true;
          }
        }
      ),
      timezone_id: Yup.string().required("Timezone is required"),
    }),
    onSubmit: (values) => {
        updateProfileApi(values)
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setIsSubmitted(true);
            // update values in local storage only is_brand and brand_name
            const userInfo = localStorage.getItem("_lluser");
            const userInfoObj = JSON.parse(userInfo);
            userInfoObj.is_brand = values.is_brand;
            userInfoObj.brand_name = values.brand_name;
            localStorage.setItem("_lluser", JSON.stringify(userInfoObj));
            localStorage.setItem("_lltimezone", stringTimezone);
            getTimezoneAbbr(stringTimezone);
            localStorage.setItem("_lltimezoneabbr", stringAbbreviation);
            dispatch(setDashboardApiTriggered(false));

            setTimeout(() => {
                navigate("/dashboard");
            }, 1500);
            dispatch(fetchProfile());
          } else {
            toast.error(res?.data?.message, {
              position: "top-right",
              autoClose: 700,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const getProfileDetails = async () => {
    profileDetailsApi({ username: username })
      .then((res) => {
        setUserProfile(res?.data?.data);
        setFieldValue("first_name", res?.data?.data?.first_name);
        setFieldValue("last_name", res?.data?.data?.last_name);
        setFieldValue("email", res?.data?.data?.email);
        setFieldValue("about_me", res?.data?.data?.about_me);
        setFieldValue("user_name", res?.data?.data?.user_name);

        const timezoneId = res?.data?.data?.TimeZone?.id;
        const timezoneUtcOffset = res?.data?.data?.TimeZone?.utc_offset;
        const timezone = res?.data?.data?.TimeZone?.timezone;
        const abbr = res?.data?.data?.TimeZone?.abbreviation;
        setStringAbbreviation(abbr);
        setStringTimezone(timezone);
        
        const timezoneObj = {
          value: timezoneId,
          label: `GMT ${timezoneUtcOffset} - ${timezone}`,
        }


        setSelectedTimezone(timezoneObj);
        setFieldValue("timezone_id", timezoneId);

        const isBrand = res?.data?.data?.is_brand;
        const brandValue = res?.data?.data?.brand_name;

        if (isBrand === 1) {
          setFieldValue("is_brand", 1);
          setFieldValue("brand_name", brandValue);
          setSelectedUserType("Brand");
        } else {
          setFieldValue("is_brand", 0);
          setSelectedUserType("Individual");
        }

        const category = res?.data?.data?.UserCategory?.map((item, index) => {
          return { value: item?.category_id, label: item?.categoryName };
        });
        setSelectedCategory(category);

        const language = res?.data?.data?.UserLanguage?.map((item, index) => {
          return { value: item?.language_id, label: item?.languageName };
        });

        setFieldValue(
          "languageIds",
          language?.map((item) => item?.value)
        );
        setFieldValue(
          "categoryIds",
          category?.map((item) => item?.value)
        );

        if (res?.data?.data?.profileImageUrl === null) {
          setFile(AvatarImage);
        }

        setSelectedLanguage(language);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileDetails();
    getLanguageList(activeData);
    getCategoryList(activeData);
    getSystemSettingList();
  }, []);

  const handleProfileImageUpload = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));

    const formData = new FormData();
    formData.append("profile_image", e.target.files[0]);
    updateProfileImageApi(formData)
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          dispatch(fetchProfile());
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTextAreaChange = (event) => {
    setFieldValue("about_me", event.target.value);
    adjustTextAreaHeight(event.target);
  };

  const adjustTextAreaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleChangeUserOption = (e) => {
    setSelectedUserType(e.target.value);
    if (e.target.value === "Brand") {
      formik.setFieldValue("is_brand", 1);
    } else {
      formik.setFieldValue("is_brand", 0);
    }
  };

  const handleSelectTimezone = (selectedOption) => {
    const abbr = selectedOption?.abbreviation;
    setStringAbbreviation(abbr);
    const plainTimezone = selectedOption?.plainValue;
    setStringTimezone(plainTimezone);
    const selectedValue = selectedOption?.value;
    setSelectedTimezone(selectedOption);
    setFieldValue("timezone_id", selectedValue);
  };

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Layout>
      <section className={`${isMobileView ? "main mt-3" : "main mt-5 py-5"}`}>
        <div className="container px-8">
          <div className="row">
            <div className="inner-block">
              <Link onClick={goBack}>
                <div className="icon-back mb-3">
                  {" "}
                  <i className="fa fa-arrow-left"></i>
                </div>
              </Link>

              <div className="d-flex justify-content-center">
                <div className="card mb-5 mob-card edit-profile-card">
                  <div className="row edit-profile-row">
                    <div className="text-center mb-4">
                      <div className="position-relative d-inline-block">
                        <img
                          src={file ? file : userProfile?.profileImageUrl}
                          alt="Profile Image"
                          className="profile-image rounded-circle img-property-fit"
                          height="100px"
                          width="100px"
                        />
                        <div className="edit-icon">
                          <label
                            htmlFor="file-input"
                            className="file-input-label"
                          >
                            <i className="fa fa-edit"></i>
                            <input
                              id="file-input"
                              type="file"
                              className="d-none"
                              onChange={handleProfileImageUpload}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <CustomInput
                        id="first_name"
                        type="text"
                        label="First Name"
                        placeholder="First Name"
                        value={values.first_name || ""}
                        onChange={handleChange}
                        error={touched.first_name && errors.first_name}
                        touched={touched.first_name}
                      />
                    </div>

                    <div className="col-md-6">
                      <CustomInput
                        id="last_name"
                        type="text"
                        label="Last Name"
                        placeholder="Last Name"
                        value={values.last_name || ""}
                        onChange={handleChange}
                        error={touched.last_name && errors.last_name}
                        touched={touched.last_name}
                      />
                    </div>

                    <div className="col-md-12 mt-3 mb-3">
                      <CustomInput
                        id="email"
                        type="email"
                        label="Email"
                        placeholder="Enter Email"
                        value={values.email || ""}
                        onChange={handleChange}
                        error={touched.email && errors.email}
                        touched={touched.email}
                        disabled={true}
                      />
                    </div>

                    <div className="col-md-6">
                      <CustomInput
                        id="username"
                        type="text"
                        label="Username"
                        placeholder="Enter Username"
                        value={values.user_name || ""}
                        onChange={handleChange}
                        error={touched.user_name && errors.user_name}
                        touched={touched.user_name}
                        disabled={true}
                      />
                    </div>

                    <div className="col-md-6 d-flex align-items-center mt-3">
                      <div className="form-outline mt-4">
                        <label className="form-label me-4" htmlFor="form3Example3">
                          I am a
                        </label>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="Individual"
                            onChange={handleChangeUserOption}
                            style={{ padding: "unset", marginTop: "1px" }}
                            checked={selectedUserType == "Individual"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            Individual
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="Brand"
                            onChange={handleChangeUserOption}
                            style={{ padding: "unset", marginTop: "1px" }}
                            checked={selectedUserType == "Brand"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            Brand
                          </label>
                        </div>
                      </div>
                    </div>

                    {selectedUserType === "Brand" && (
                      <div className="col-md-12 mt-3 mb-3">
                        <CustomInput
                          id="brand_name"
                          type="text"
                          label="Brand Name"
                          placeholder="Enter Brand Name"
                          value={values.brand_name || ""}
                          onChange={handleChange}
                          error={touched.brand_name && errors.brand_name}
                          touched={touched.brand_name}
                        />
                      </div>
                    )}

                    <div className="col-md-12 mt-3">
                      <div className="form-outline mb-4">
                        <label className="form-label mb-2" htmlFor="">
                          Interests (Minimum 2)
                        </label>
                        <Select
                          name="categoryIds"
                          className=""
                          id="multiple-select-field5"
                          placeholder="Select Topics"
                          isMulti
                          options={categoryList?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              boxShadow: "none",
                              borderColor: "#ced4da",
                              "&:hover": {
                                borderColor: "#ced4da",
                              },
                            }),
                          }}
                          onChange={(selectedOption) => {
                            const selectedValue = selectedOption.map(
                              (item) => item.value
                            );
                            setSelectedCategory(selectedOption);
                            setFieldValue("categoryIds", selectedValue);
                          }}
                          value={selectedCategory || []}
                        />
                        {errors.categoryIds && touched.categoryIds && (
                          <div className="text-danger">
                            {errors.categoryIds}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-outline mb-4">
                        <label className="form-label mb-2" htmlFor="">
                          Languages (Minimum 2)
                        </label>
                        <Select
                          name="languageIds"
                          className=""
                          id="multiple-select-field6"
                          placeholder="Select Languages"
                          isMulti
                          options={languageList?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              boxShadow: "none",
                              borderColor: "#ced4da",
                              "&:hover": {
                                borderColor: "#ced4da",
                              },
                            }),
                          }}
                          onChange={(e) => {
                            const f = e.map((item, index) => {
                              return item.value;
                            });
                            setFieldValue("languageIds", f);
                            setSelectedLanguage(e);
                          }}
                          value={selectedLanguage}
                        />
                        {errors.languageIds && touched.languageIds && (
                          <div className="text-danger">
                            {errors.languageIds}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-outline mb-4">
                        <label className="form-label mb-2" htmlFor="">
                          Timezone
                        </label>
                        <TimezoneSelect
                          timezoneList={timezoneList}
                          handleSelectTimezone={handleSelectTimezone}
                          selectedTimezone={selectedTimezone || []}
                          isOutlined={true}
                        />

                        {errors.timezone_id && touched.timezone_id && (
                          <div className="text-danger">
                            {errors.timezone_id}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label className="form-label mb-2 d-flex justify-content-between" htmlFor="">
                        <span>
                        About Me
                        </span>
                          
                        <span>
                          {values.about_me?.length || 0}/{maxAboutUsLimit}
                        </span>
                        
                      </label>
                      
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.about_me || ""}
                        config={{
                          height: "600px",
                         
                         }}
                       
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("about_me", data);
                        }}
                      />
                      {errors.about_me && touched.about_me && (
                        <div className="text-danger">{errors.about_me}</div>
                      )}
                    </div>
                  </div>

                  <div className="row justify-content-center mb-4">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-login btn-lg btn-block w-100 btn-submit"
                        onClick={handleSubmit}
                      >
                        Update Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </Layout>
  );
};

export default EditProfilePage;
