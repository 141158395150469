import { getTimezoneApi } from "api/sessionApi";
import React from "react";
import AsyncSelect from "react-select/async";

const TimezoneSelect = ({
  timezoneList,
  handleSelectTimezone,
  selectedTimezone,
  isOutlined,
}) => {
  const loadOptions = (inputValue, callback) => {
    getTimezoneApi({ page: 1, limit: 10, search: inputValue })
      .then((res) => {
        if (res?.data?.success) {
          const data = res?.data?.data?.rows?.map((item) => ({
            value: item.id,
            label: `GMT ${item.utc_offset} - ${item.timezone} ${item.abbreviation}`,
            plainValue: item.timezone,
            abbreviation: item.abbreviation,
          }));
          callback(data);
        }
      })
      .catch((err) => {
        console.log(err);
        callback([]);
      });
  };

  let styles = {};

  if (isOutlined) {
    styles = {
      control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: "#ced4da",
        "&:hover": {
          borderColor: "#ced4da",
        },
      }),
    };
  }


  return (
    <AsyncSelect
      name="timezone_id"
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      isClearable
      placeholder="Select Time Zone"
      onChange={handleSelectTimezone}
      value={selectedTimezone || []}
      styles={styles}
    />
  );
};

export default TimezoneSelect;
