import Layout from "layouts/Index";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useParams } from "react-router-dom";
import BannerImage from "../../assets/images/banner.png";
import ShareIcon from "../../assets/images/shareicon.svg";
import BookmarkIcon from "../../assets/images/icon-bookmark.svg";
import BookmarkIconFilled from "../../assets/images/icon-bookmark-filled.svg";
import AvatarImage from "../../assets/images/profile-placeholder.png";
import { useDispatch, useSelector } from "react-redux";
import { getSessionDetailApi } from "api/sessionApi";
import { sendSessionRequestApi } from "api/sessionApi";
import { setDashboardApiTriggered } from "actions/DashboardActions";
import { cancelJoinRequestApi } from "api/sessionApi";
import ConfirmationAlert from "components/Utilities/ConfirmationAlert";
import createShortDynamicLink from "services/DynamicLink/shortLink";
import { acceptRejectRequestApi } from "api/sessionApi";
import { getBookmarkApi } from "api/bookmarkApi";
import { createBookmarkApi } from "api/bookmarkApi";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { isUserLoggedIn } from "helpers/common_helper";
import { formatGlobalDate } from "helpers/common_helper";
import { formatGlobalTime } from "helpers/common_helper";
import { handleImageError } from "helpers/common_helper";
import ShareModal from "components/Utilities/ShareModal";
import FeedbackModal from "components/Utilities/FeedbackModal";
import VerifyEmailModal from "./Modal/VerifyEmailModal";
import { getSystemTimezoneAbbr } from "helpers/common_helper";
import LocationIcon from "../../assets/images/icon-location.png";

const SessionDetailPage = () => {
  const params = useParams();
  const session_id = params?.id;
  const [loading, setLoading] = useState(false);
  const isMobileView = useMediaQuery({ query: "(max-width: 767px)" });
  const dispatch = useDispatch();
  const [sessionDetails, setSessionDetails] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestStatus, setRequestStatus] = useState({});
  const [isShowJoinButton, setIsShowJoinButton] = useState(false);
  const [language, setLanguage] = useState("");
  const [dynamicLink, setDynamicLink] = useState("");
  const isLogged = isUserLoggedIn();
  const [bookmark, setBookmark] = useState(false);

  const profile = useSelector((state) => state?.profile?.profile);

  const isEmailVerified = profile?.is_email_verified === 1 ? true : false;

  const getSessionDetails = () => {
    setLoading(true);
    getSessionDetailApi({ session_id: session_id })
      .then((res) => {
        setSessionDetails(res?.data?.data?.session);
        setLanguage(res?.data?.data?.session?.languages[0]?.languageName);
        setRequestStatus(res?.data?.data?.requestStatus);
        const sessionStartDateTime = res?.data?.data?.session?.start_date_time;
        // const sessionEndDateTime = res?.data?.data?.session?.end_date_time;
        const currentDateTime = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        const currenDate = moment.utc().format("YYYY-MM-DD");

        const formattedStartDate = moment
          .utc(sessionStartDateTime)
          .format("YYYY-MM-DD");

        // const formattedDate = moment
        //   .utc(sessionEndDateTime)
        //   .format("YYYY-MM-DD HH:mm:ss");
        // const before15Minutes = moment
        //   .utc(formattedDate)
        //   .subtract(15, "minutes")
        //   .format("YYYY-MM-DD HH:mm:ss");
        // const showBefore15Minutes = moment
        //   .utc(currentDateTime)
        //   .isSameOrAfter(before15Minutes);

        // setIsShowJoinButton(showBefore15Minutes);

        if (currenDate == formattedStartDate) {
          setIsShowJoinButton(true);
        } else {
          setIsShowJoinButton(false);
        }

        // const sessionEndDateTime = res?.data?.data?.session?.end_date_time;
        // const formattedEndDate = moment
        //   .utc(sessionEndDateTime)
        //   .format("YYYY-MM-DD HH:mm:ss");
        //   const isSessionTimeOver = moment.utc(currentDateTime).isAfter(formattedEndDate);
        //   if(isSessionTimeOver){
        //     setIsShowJoinButton(false);
        //   }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendSessionJoinRequest = () => {
    sendSessionRequestApi({ session_id: session_id })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsSubmitted(true);
          dispatch(setDashboardApiTriggered(true));
          getSessionDetails();
        } else {
          toast.warning(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancelJoinRequest = () => {
    cancelJoinRequestApi({ requestId: requestStatus?.id })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setIsSubmitted(true);
          getSessionDetails();
        } else {
          toast.warning(res?.data?.message, {
            position: "top-right",
            autoClose: 700,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showConfirmationAlert = () => {
    toast(
      <ConfirmationAlert
        message="Are you sure you want to delete this session?"
        onConfirm={handleCancelJoinRequest}
      />,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
      }
    );
  };

  useEffect(() => {
    getSessionDetails();
  }, [session_id, isSubmitted]);

  useEffect(() => {
    if (isSubmitted) {
      const timeoutId = setTimeout(() => {
        window.$("#sessionDetailsModal").modal("hide");
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [isSubmitted]);

  const handleShareLink = () => {
    window.$("#sessionDetailsModal").modal("hide");

    createShortDynamicLink("session-info", session_id)
      .then((res) => {
        setDynamicLink(res?.shortLink);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const acceptInviteSessionRequest = (data) => {
    acceptRejectRequestApi(data).then((res) => {
      if (res?.data?.success) {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsSubmitted(true);
        dispatch(setDashboardApiTriggered(true));
        getSessionDetails();
      } else {
        toast.warning(res?.data?.message, {
          position: "top-right",
          autoClose: 700,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    });
  };

  const getBookmark = () => {
    getBookmarkApi({ session_id: session_id })
      .then((res) => {
        setBookmark(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateBookmark = () => {
    createBookmarkApi({ session_id: session_id })
      .then((res) => {
        if (res?.data?.success) {
          getBookmark();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBookmark();
  }, [session_id]);
  return (
    <Layout>
      <section className={`${isMobileView ? "main mt-3" : "main mt-5 py-5"}`}>
        <div className="container px-8">
          <div className="row">
            <div className="inner-block">
              <Link to={`/dashboard`}>
                <div className="icon-back mb-3">
                  {" "}
                  <i className="fa fa-arrow-left"></i>
                </div>
              </Link>

              {loading ? (
                <>
                  <div className="justify-content-center d-flex align-items-center">
                    <div className="loadingio-spinner-rolling-26k4myxj9b9">
                      <div className="ldio-y9y7x49yybs">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="card mob-card"
                    style={{ marginBottom: isMobileView ? "8rem" : "3rem" }}
                  >
                    <div className="heading-text">
                      <h4 className="title-head">{sessionDetails?.title}</h4>
                      <div className="datetime mb-2">
                        <span className="date span-text">
                          {formatGlobalDate(sessionDetails?.start_date_time)}
                        </span>
                        <span className="time ms-3 span-text">
                          {formatGlobalTime(
                            sessionDetails?.start_date_time,
                            sessionDetails?.timezone
                          ) +
                            " to " +
                            formatGlobalTime(
                              sessionDetails?.end_date_time,
                              sessionDetails?.timezone
                            ) +
                            " " +
                            `(${getSystemTimezoneAbbr()})`}
                        </span>
                        <span
                          className="session-type-badge-detail ms-5"
                          style={{
                            backgroundColor: sessionDetails?.sessionTypeColor,
                          }}
                        >
                          {sessionDetails?.sessionTypeName}
                        </span>
                        {!isShowJoinButton &&
                          requestStatus?.status === "Accepted" && (
                            <span className="ms-4">
                              {`(You can join session on date ${formatGlobalDate(
                                sessionDetails?.start_date_time
                              )})`}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="card-header text-center bg-white pb-4">
                      <img
                        src={
                          sessionDetails?.bannerImageUrl === null
                            ? BannerImage
                            : sessionDetails?.bannerImageUrl
                        }
                        alt="sessionimage"
                        className="card-img-top img-property-fit"
                        onError={handleImageError}
                        style={{ height: isMobileView ? "fit-content" : "" }}
                        height="300"
                        width="300"
                      />
                      <div className="card-img-overlay session-detail-page-overlay">
                        <div className="card-image-overlay-content">
                          <span
                            className="card-title badge session-detail-page-badge"
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            {language}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="card-text">
                            <Link
                              to={`/user-profile/${sessionDetails?.user?.user_name}`}
                            >
                              <span>
                                <img
                                  src={
                                    sessionDetails?.user?.profileImageUrl ===
                                    null
                                      ? AvatarImage
                                      : sessionDetails?.user?.profileImageUrl
                                  }
                                  alt="userimage"
                                  className="rounded-circle img-property-fit"
                                  height="35"
                                  width="35"
                                />
                              </span>
                              <span className="ms-3">
                                {sessionDetails?.user?.first_name +
                                  " " +
                                  sessionDetails?.user?.last_name}
                              </span>
                            </Link>
                          </div>
                        </div>

                        <div className="col-md-6 d-flex justify-content-end">
                          <div className="card-text d-flex">
                            <Link
                              data-bs-target="#shareModal"
                              data-bs-toggle="modal"
                              onClick={handleShareLink}
                            >
                              <span className="detail-icons me-3">
                                <img
                                  src={ShareIcon}
                                  alt="share"
                                  height="20"
                                  width="20"
                                />
                              </span>
                            </Link>
                            <Link onClick={handleCreateBookmark}>
                              <span className="detail-icons">
                                <img
                                  src={
                                    bookmark ? BookmarkIconFilled : BookmarkIcon
                                  }
                                  alt="bookmark"
                                  height="20"
                                  width="20"
                                />
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <hr className="divider mb-0" />

                      <div className="text-center mt-3">
                        <span className="">About</span>
                      </div>

                      <div className="card-text mt-3">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: sessionDetails?.about,
                          }}
                        ></p>
                      </div>

                      

                      {/* Location */}

                      {sessionDetails?.type === "Offline" && (
                        <>
                        <hr className="divider mb-0" />

                        
                          <div className="d-flex align-items-center mt-3">
                            <span className="detail-icons me-2">
                              <img
                                src={LocationIcon}
                                alt="locationicon"
                                height="20"
                                width="20"
                              />
                            </span>

                            <div className="card-text me-2">
                              <p>{sessionDetails?.location}</p>
                            </div>

                            <div>
                              <Link to={sessionDetails?.map_link}>
                                <span className="view-on-map">
                                  (View On Map)
                                </span>
                              </Link>
                            </div>
                          </div>
                        </>
                      )}

                      {/* Location */}

                      <div className="action-buttons mt-4">
                        <div className="d-flex justify-content-center">
                          {isLogged ? (
                            <>
                              {sessionDetails?.status === "past" && (
                                <>
                                  <div className="d-flex">
                                    <Link className="session-detail-label">
                                      Session Completed
                                    </Link>

                                    {requestStatus?.status === "Accepted" && (
                                      <Link
                                        className="btn btn-send3"
                                        data-bs-target="#feedbackModal"
                                        data-bs-toggle="modal"
                                      >
                                        Give Feedback
                                      </Link>
                                    )}
                                  </div>
                                </>
                              )}
                              {sessionDetails?.participateCount ==
                                sessionDetails?.participate_limit && (
                                <Link className="session-detail-label">
                                  Session Full
                                </Link>
                              )}
                              {sessionDetails?.status === "upcoming" &&
                                requestStatus &&
                                requestStatus?.status === "Pending" && (
                                  <>
                                    <div className="d-flex">
                                      <Link className="session-detail-label">
                                        Request Sent
                                      </Link>
                                      <Link
                                        className="btn btn-send3"
                                        onClick={() => showConfirmationAlert()}
                                      >
                                        Cancel Request
                                      </Link>
                                    </div>
                                  </>
                                )}

                              {sessionDetails?.status === "upcoming" &&
                                requestStatus &&
                                requestStatus?.status === "Invited" && (
                                  <>
                                    <div className="d-flex">
                                      <Link
                                        className="session-detail-label"
                                        onClick={() =>
                                          acceptInviteSessionRequest({
                                            requestId: requestStatus?.id,
                                            type: "Accepted",
                                          })
                                        }
                                      >
                                        Accept Request
                                      </Link>
                                      <Link
                                        className="btn btn-send3"
                                        onClick={() => showConfirmationAlert()}
                                      >
                                        Cancel Request
                                      </Link>
                                    </div>
                                  </>
                                )}
                              {sessionDetails?.status === "upcoming" &&
                                requestStatus &&
                                requestStatus?.status === "Accepted" && (
                                  <div className="session-label-container">
                                    <Link className="session-detail-label">
                                      Request Accepted
                                    </Link>{" "}
                                    {isShowJoinButton ? (
                                      <Link
                                        className="btn btn-detail"
                                        to={sessionDetails?.meeting_link}
                                        target="_blank"
                                      >
                                        Join
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              {sessionDetails?.status === "upcoming" &&
                                requestStatus &&
                                requestStatus?.status === "Rejected" && (
                                  <Link className="session-detail-label-rejected">
                                    Request Rejected
                                  </Link>
                                )}
                              {((sessionDetails?.status === "upcoming" &&
                                requestStatus === null) ||
                                requestStatus === undefined) && (
                                <>
                                  {isEmailVerified ? (
                                    <>
                                      <Link
                                        onClick={() => sendSessionJoinRequest()}
                                        className="btn btn-send2"
                                      >
                                        Send Join Request
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link
                                        data-bs-target="#verifyEmail"
                                        data-bs-toggle="modal"
                                        className="btn btn-send2"
                                      >
                                        Send Join Request
                                      </Link>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Link
                                className="btn btn-send2"
                                onClick={() => {
                                  window.location.href = "/login";
                                }}
                              >
                                Login to send request
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      <ShareModal shareableLink={dynamicLink} />
      <FeedbackModal session_id={session_id} />
      <VerifyEmailModal email={profile?.email} />
    </Layout>
  );
};

export default SessionDetailPage;
